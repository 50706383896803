<template>
  <div class="bg-success">
    <div class="nav">
      <NavbarComp
        @searchValue="getCity"
        @flag="getFlag"
        @click="[handleCity(),goDown()]"
      />
      <NewInput
        type="text"
        v-model="searchValue"
        @input="handleInput"
        @click="flush"
        class="input"
      />
    </div>
  </div>
  <div class="carousels">
    <CarouselVertical />
    <CarouselComp />
  </div>
  <div
    id="results"
    class="results d-flex flex-row flex-wrap"
    v-if="searchValue.length > 2 && results.length >= 0"
  >
    <h3 v-if="results.length > 1" class="w-100 py-2 text-light bg-success">
      {{ results.length }} results for
      <span class="text-warning text-uppercase">"{{ searchValue }}"</span>
    </h3>
    <h3
      v-if="results.length == 0 || results.length == 1"
      class="w-100 py-2 text-light bg-success"
    >
      {{ results.length }} result for
      <span class="text-warning text-uppercase">"{{ searchValue }}"</span>
    </h3>
    <ItemComp v-for="item in results" :item="item" :key="item.id" />
  </div>
  <FooterComp class="footer" />
</template>

<script>
import axios from "axios";
import debounce from "lodash.debounce";

const API = "https://app.ticketmaster.com/discovery/v2/events?";
const APIKEY = "7elxdku9GGG5k8j0Xm8KWdANDgecHMV0";
import NavbarComp from "@/components/NavbarComp.vue";
import CarouselComp from "@/components/CarouselComp.vue";
import CarouselVertical from "@/components/CarouselVertical.vue";
import ItemComp from "@/components/ItemComp.vue";
import NewInput from "@/components/NewInput.vue";
import FooterComp from "@/components/FooterComp.vue";
import { ref } from "vue";
var VueScrollTo = require("vue-scrollto");
export default {
  name: "App",
  components: {
    NavbarComp,
    CarouselComp,
    CarouselVertical,
    ItemComp,
    NewInput,
    FooterComp,
  },
  setup() {
    const searchValue = ref("");
    const results = ref([]);
    const flag = ref(true);
    return {
      searchValue,
      results,
      flag,
    };
  },
  methods: {
    handleInput: debounce(function () {
      // console.log(this.searchValue);
      axios
        .get(
          `${API}apikey=${APIKEY}&keyword=${this.searchValue}&locale=*&sort=date,asc&countryCode=PL`
        )
        .then((response) => {
          this.results = response.data._embedded.events;
          VueScrollTo.scrollTo("#results", {offset:-100});
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    handleCity() {
      // console.log(this.searchValue);
      axios
        .get(
          `${API}apikey=${APIKEY}&keyword=${this.searchValue}&locale=*&sort=date,asc&countryCode=PL`
        )
        .then((response) => {
          this.results = response.data._embedded.events;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    flush() {
      this.results.splice(0);
      this.searchValue = "";
      this.flag = true;
    },
    getCity(value) {
      this.searchValue = value;
    },
    getFlag(value) {
      this.flag = value;
    },
    goDown() {
      if (!this.flag) {
        if(screen.width>500){
        VueScrollTo.scrollTo("#results", {offset:-100});}
        else{
          VueScrollTo.scrollTo("#results", {offset:-500})
        }
      }
      this.flag = true;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Carter+One&family=Racing+Sans+One&family=Roboto:ital,wght@0,300;0,400;0,700;1,100&display=swap");
#app {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #dadada;
  min-height: 100vh;
  overflow: hidden;
}
html {
  scroll-behavior: smooth;
}
h2,
h3,
h4 {
  margin: 0;
}
.nav {
  top: -60px;
  opacity: 0.2;
  margin: 0 auto;
  position: relative;
  animation: dropdown 0.4s linear forwards;
  animation-delay: 0.1s;
  z-index: 1;
}
@keyframes dropdown {
  to {
    top: 0;
    opacity: 1;
  }
}
.input {
  position: static;
}
.results {
  display: flex;
  grid-template-columns: 1fr 1fr;
  grid-gap:6px;
  margin: 0 auto;
  justify-content: center;
  margin-bottom: 8px;
}
/* .results:after{
  content: '';
  flex: auto;
} */
.results h3 {
  opacity: 0;
  animation: opacity 0.4s linear forwards;
  animation-delay: 0.9s;
}
@keyframes opacity {
  100% {
    opacity: 1;
  }
}
.carousels {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  transform: translateX(-60px);
  animation: center 0.4s linear forwards;
  animation-delay: 0.1s;
  opacity: 0.2;
}
@keyframes center {
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
.footer {
  transform: translateY(60px);
  animation: center 0.4s linear forwards;
  animation-delay: 0.1s;
  opacity: 0.2;
}
@keyframes bottom {
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .results {
    max-width: 1100px;
  }
  .input {
    position: absolute;
    right: 0;
    border-radius: 5px;
  }
  .nav {
    max-width: 1100px;
  }
  .results h3 {
    border-radius: 5px;
  }
}
@media (min-width: 1480px) {
  .carousels {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .results {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 2px;
  }
  .results,
  .nav,
  .carousels {
    max-width: 1465px;
  }
}
@media (min-width: 2000px) {
  .results,
  .nav,
  .carousels {
    max-width: 2200px;
  }
}
</style>
