<template>
  <div class="d-flex justify-content-center input py-2 col-12 col-xl-4">
    <span class="input-group-text" id="basic-addon1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-search text-success"
        viewBox="0 0 16 16"
      >
        <path
          d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
        ></path>
      </svg> </span
    ><input
      v:bind="$attrs"
      :value="modelValue"
      @input="handleChange"
      name="Input"
      placeholder="Event, artist, city (min. 3 chars)"
      maxlength="30"
    />
  </div>
</template>

<script>
export default {
  name: "NewInput",
  props: {
    modelValue: {
      type: String,
      default: "text",
    },
  },
  methods: {
    handleChange(e) {
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style scoped>
* {
  background-color: white;
  border: none;
}
.input {
  margin: 0 auto;
}
input {
  width: 100%;
}
input:focus {
  outline: none;
}
@media (min-width: 1200px) {
  .input {
    margin: 11px 0 0 0;
  }
}
</style>
