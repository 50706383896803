<template>
  <div class="NavWrapper w-100 px-2">
    <nav class="navbar navbar-expand-md navbar-dark bg-success">
      <div class="container-fluid p-0">
        <a class="navbar-brand"
          ><img src="../assets/ticket.png" alt=""> Ticket Market</a
        >
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-lg-0 flex-row flex-wrap">
            <li class="nav-item col-4 col-sm-auto">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li class="nav-item dropdown col-4 col-sm-auto">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Cities
              </a>
              <ul
                class="dropdown-menu bg-success"
                aria-labelledby="navbarDropdown"
              >
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Kraków'),toggle()]">Kraków</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Warszawa'),toggle()]">Warszawa</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Wrocław'),toggle()]">Wrocław</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Łódź'),toggle()]">Łódź</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Gdańsk'),toggle()]">Gdańsk</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Poznań'),toggle()]">Poznań</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Szczecin'),toggle()]">Szczecin</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Katowice'),toggle()]">Katowice</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Rzeszów'),toggle()]">Rzeszów</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" @click="[onClickButton('Chorzów'),toggle()]">Chorzów</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <ul class="nav bg-success"></ul>
  </div>
</template>

<script>
export default {
  name: "NavbarComp",
  methods: {
     onClickButton (searchValue, flag) {
         this.$emit('searchValue', searchValue)
         this.$emit('flag', flag)
     },
         toggle () {
        this.flag = false;
        console.log(this.flag);
    }
   }
};
</script>

<style scoped>
i{
  color: rgb(211, 230, 47);
}
li:last-child {
  border-right: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
li a {
  color: #e4e4e4 !important;
}
li {
  margin: 5px 0;
  padding: 0 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}
.dropdown-menu {
  border: none;
}
.navbar-brand {
  display: flex;
  align-items: center;
  font-family: "Carter One", cursive;
  font-size: 24px;
}
img{
  margin-right: 10px;
  height: 40px;
  text-align: center;
}
@media (max-width: 540px) {

  .dropdown li {
    border-right: none;
    text-align: center;
  }
}
</style>
