<template v-if="item">
  <div :style="style" class="item my-2">
    <div class="item-overlay text-light">
      <div class="item-info">
        <p>
          {{ panel.date }} <span v-if="panel.time">| {{ panel.time }}</span>
        </p>
        <p class="">{{ panel.venues }}, {{ panel.city }}</p>
        <a :href="panel.link" class="btn text-light bg-success px-4"
          >Buy a ticket</a
        >
      </div>
    </div>
    <div class="title">
      <h5 class="text-uppercase text">{{ panel.title }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemComp",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    style() {
      if (this.item.images[8].width > 310) {
        return `background-image: url("${this.panel.photo}")`;
      }
      if (this.item.images[7].width > 310) {
        return `background-image: url("${this.panel.photo2}")`;
      } else {
        return `background-image: url("${this.panel.photo3}")`;
      }
    },
    panel() {
      return {
        photo: this.item.images[8].url,
        photo2: this.item.images[7].url,
        photo3: this.item.images[0].url,
        title: this.item.name,
        date: this.item.dates.start.localDate,
        time: this.item.dates.start.localTime,
        venues: this.item._embedded.venues[0].name,
        city: this.item._embedded.venues[0].city.name,
        link: this.item.url,
        // priceMin: this.item.priceRanges[0].min,
        // priceMax: this.item.priceRanges[0].max,
        // currency: this.item.priceRanges[0].currency,
      };
    },
  },
};
</script>

<style scoped>
.item {
  position: relative;
  height: 100vw;
  width: 95%;
  margin: 0 auto;
  box-shadow: 0px 0px 7px 0px rgba(66, 68, 90, 0.7);
  overflow: hidden;
  background-size: 180%;
  background-repeat: no-repeat;
  background-position: 50% 0%;
  transition: 0.3s linear;
  border-radius: 5px;
}
.item-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s ease-in;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  transform: scale(0);
}
.item:hover .item-overlay {
  opacity: 1;
  transform: scale(1);
}
.item:hover {
  background-size: 200%;
}
.item-overlay .btn {
  width: 180px;
  justify-content: center;
}
.title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0%;
  left: 50%;
  transform: translatex(-50%);
  width: 101%;
  background: #000;
  color: #dadada;
  height: 65px;
  padding: 10px;
}
.title h5 {
  margin: 0;
  font-size: 14px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.item-info:hover a {
  box-shadow: inset 0px 0px 79px -45px rgba(0, 0, 0, 1);
}
@media (min-width: 450px) {
  .item {
    height: 50vw;
    width: 45%;
    margin: 10px;
    background-position: 50% 0%;
  }
}
@media (min-width: 760px) {
  .item {
    height: 35vw;
    width: 30%;
    margin: 10px;
    background-position: 50% 0%;
  }
  .item-overlay {
    justify-content: flex-start;
    padding-top: 30%;
  }
}
@media (min-width: 1200px) {
  .item {
    height: 30vw;
    width: 32%;
    margin: 5px 0;
  }
    .item-overlay {
    justify-content: flex-start;
    padding-top: 25%;
  }
  .title h5 {
font-size: 16px;
}
}
@media (min-width: 1480px) {
  .item {
    height: 320px;
    width: 24%;
    margin: 5px;
  }
}
</style>
