<template>
  <footer class="bg-success text-light">
    <div class="scrollUp" @click="scrollTop()">
      <a><i class="bi bi-caret-up-fill text-success fs-5"></i></a>
    </div>
    <div class="container px-4 text-center text-md-start">
      <div class="row">
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h5
            class="text-uppercase my-0 my-md-4"
            @click="isActiveContact = !isActiveContact"
          >
            Contact
          </h5>
          <ul
            class="list-unstyled mb-0 d-md-block"
            :class="{ 'd-block': isActiveContact }"
          >
            <li class="my-2">
              <span class="text-light text-decoration-none"
                ><i class="bi bi-house-door-fill me-2"></i>ul. Wawelska 5,
                Krakow</span
              >
            </li>
            <li class="my-2">
              <a
                href="mailto:contact@email.com"
                class="text-light text-decoration-none"
                ><i class="bi bi-envelope-fill me-2 light"></i
                >contact@email.com</a
              >
            </li>
            <li class="my-2">
              <a href="tel:123-456-789" class="text-light text-decoration-none"
                ><i class="bi bi-telephone-fill me-2"></i>123-456-789</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5
            class="text-uppercase my-0 my-md-4"
            @click="isActiveTerms = !isActiveTerms"
          >
            Terms
          </h5>
          <ul
            class="list-unstyled mb-0 d-md-block"
            :class="{ 'd-block': isActiveTerms }"
          >
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Legal</a>
            </li>
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none"
                >Privacy Policy</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5
            class="text-uppercase my-0 my-md-4"
            @click="isActiveCompany = !isActiveCompany"
          >
            Company
          </h5>
          <ul
            class="list-unstyled mb-0 d-md-block"
            :class="{ 'd-block': isActiveCompany }"
          >
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">About Us</a>
            </li>
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Press</a>
            </li>
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Support</a>
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h5
            class="text-uppercase my-0 my-md-4"
            @click="isActiveSocial = !isActiveSocial"
          >
            Social
          </h5>
          <ul
            class="list-unstyled mb-0 d-md-block"
            :class="{ 'd-block': isActiveSocial }"
          >
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Facebook</a>
            </li>
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Twitter</a>
            </li>
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Instagram</a>
            </li>
            <li class="my-2">
              <a href="#" class="text-light text-decoration-none">Linkedin</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center p-3 copyright">
      <span
        >© 2022 Copyright
        <a
          href="https://www.kiwwwano.com"
          class="text-warning text-decoration-none"
          >Kiwwwano.com</a
        ></span
      >
      <p class="test">
        Strona ma charakter testowy. Właściciel strony nie oferuje sprzedaży
        biletów oraz nie pośredniczy w ich sprzedaży.
      </p>
      <p class="test">
        Test website. The website owner does not offer tickets nor act as
        an intermediary in their sale.
      </p>
    </div>
  </footer>
</template>
<script>
import { ref } from "vue";
export default {
  name: "FooterComp",
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
  },
  setup() {
    let isActiveContact = ref(false);
    let isActiveTerms = ref(false);
    let isActiveCompany = ref(false);
    let isActiveSocial = ref(false);
    return { isActiveContact, isActiveTerms, isActiveCompany, isActiveSocial };
  },
};
</script>
<style scoped>
footer {
  overflow: hidden;
}
.copyright {
  background-color: rgb(22, 121, 75);
}
.test {
  font-size: 12px;
  color: #bdbbbb;
  margin: 0;
}
h5 {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 15px;
  cursor: pointer;
  font-weight: 800;
}
.text-light {
  color: #dadada !important;
}
ul {
  display: none;
}
a:hover {
  color: rgb(187, 187, 187) !important;
}
.scrollUp {
  position: relative;
  transform: translateY(-5px);
  /* padding: 0 5px; */
  width: 50px;
  height: 30px;
  margin: 0 auto 20px auto;
  cursor: pointer;
}
.scrollUp::before {
  position: absolute;
  top: -75%;
  left: 0;
  content: "";
  padding: 0 5px;
  background-color: #dadada;
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  z-index: -1;
}
@media (min-width: 768px) {
  h5 {
    cursor: default;
  }
  .scrollUp {
  margin: 0 auto;
}
}
</style>
