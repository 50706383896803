<template>
  <div
    id="carouselExampleSlidesOnly"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item">
        <img
          src="https://s1.ticketm.net/dam/a/339/3124605b-a822-46bf-b729-85758f816339_1231261_TABLET_LANDSCAPE_LARGE_16_9.jpg"
          class="d-block"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Aerosmith</h5>
            <a
              href="https://www.ticketmaster.pl/artist/aerosmith-bilety/1539"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
      <div class="carousel-item active">
        <img
          src="https://s1.ticketm.net/dam/a/db4/fe18a6d9-7359-4621-a145-7b0ec8cc8db4_1672261_SOURCE.jpg"
          class="d-block"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Alicia Keys</h5>
            <a
              href="https://www.ticketmaster.pl/artist/alicia-keys-bilety/12774"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="https://s1.ticketm.net/dam/a/3b9/df152df4-62fe-4237-8819-fd5f988ed3b9_1089391_TABLET_LANDSCAPE_LARGE_16_9.jpg"
          class="d-block"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Sting</h5>
            <a
              href="https://www.ticketmaster.pl/artist/sting-bilety/2196"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselComp",
};
</script>

<style scoped>
.carousel {
  position: relative;
  margin: 0 auto;
  width: 100%;
  border-radius: 5px;
}
.carousel-item {
  overflow: hidden;
}
img {
  transition: 0.5s linear;
  width: 200%;
  transform: translateX(-180px);
}
.carousel:hover img {
  filter: sepia(0.4);
  transform: scale(1.01) translateX(-180px);
}
.item-overlay {
  position: absolute;
  bottom: 30px;
  right: -100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.3s ease-in;
  transform: scale(1);
}
.carousel:hover .item-overlay {
  opacity: 1;
  transform: scale(1);
  right: 30%;
  transform: translateX(50%);
}
.text {
  background-color: black;
  border-radius: 5px;
  padding: 5px 0;
}
.item:hover {
  background-size: 200%;
}
.item-overlay .btn {
  width: 180px;
  justify-content: center;
}
.title {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0%;
  left: 0%;
  transform: translatex(-50%);
  width: 101%;
  background: #000;
  color: #dadada;
  height: 65px;
  padding: 10px;
  display: none;
}
.title h5 {
  margin: 0;
  font-size: 16px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.item-info:hover a{
box-shadow: inset 0px 0px 79px -45px rgba(0, 0, 0, 1);
}
@media (min-width: 500px) {
  img {
    width: 150%;
    transform: translateX(-150px);
  }
  .carousel:hover img {
    transform: translateX(-150px);
  }
  .carousel:hover .item-overlay {
    right: 17%;
  }
  .carousel{
    margin: 0;
  }
}
@media (min-width: 1030px) {
  .carousel {
    width: 1000px;
    height: 400px !important;
    overflow: hidden;
    margin: 1rem auto 1rem auto;
    transform: translateX(0);
  }
  .item-overlay {
    bottom: 150px;
    right: -100%;
  }
  .carousel:hover .item-overlay {
    right: 12%;
  }
  img {
    width: 100%;
    margin-top: -50px;
    transform: translateX(0);
  }
  .carousel:hover img {
    filter: sepia(0.4);
    transform: scale(1.03) translateX(0);
  }
}
@media (min-width: 1480px) {
  .carousel {
    width: 1000px;
    height: 100% !important;
    overflow: hidden;
  }
  img {
    margin-top: 0;
  }
  .item-overlay {
    bottom: 30px;
    right: -100%;
  }
  .carousel:hover .item-overlay {
    right: 12%;
  }
}
@media (min-width: 2000px) {
  img {
    width: 100%;
  }
   .carousel {
    width: 1500px;
    overflow: hidden;
  }
}
</style>
