<template>
  <div
    id="carouselExampleSlidesOnly"
    class="carousel carousel-top slide"
    data-bs-ride="carousel"
  >
    <h2 class="text-light bg-success">Popular artists</h2>
    <div class="carousel-inner">
      <div class="carousel-item">
        <img
          src="https://s1.ticketm.net/dam/a/77e/676b43c4-579d-4349-a973-90693ee4e77e_1458621_TABLET_LANDSCAPE_LARGE_16_9.jpg"
          class="d-block"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Guns N' Roses</h5>
            <a
              href="https://www.ticketmaster.pl/artist/guns-n-roses-bilety/1813"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
      <div class="carousel-item">
        <img
          src="https://s1.ticketm.net/dam/a/540/f7c755ce-514b-4b1a-9ee1-78e256cc5540_1656741_TABLET_LANDSCAPE_16_9.jpg"
          class="d-block"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Backstreet Boys</h5>
            <a
              href="https://www.ticketmaster.pl/artist/backstreet-boys-bilety/4389"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
      <div class="carousel-item active">
        <img
          src="https://s1.ticketm.net/dam/a/ec5/ed9cbd60-53bc-4ea5-b42f-1c15538baec5_1661411_RETINA_PORTRAIT_3_2.jpg"
          class="d-block"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Nirvana Symfonicznie</h5>
            <a
              href="https://www.ticketmaster.pl/artist/muzyka-zespolu-nirvana-symfonicznie-bilety/1165854"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="https://s1.ticketm.net/dam/a/a2b/07bda4c9-9636-4d43-bb98-702db0014a2b_1557211_TABLET_LANDSCAPE_3_2.jpg"
          class="d-block w-100"
          alt="..."
        />
        <div class="item-overlay text-light">
          <div class="item-info">
            <h5 class="text-uppercase text">Justin Bieber</h5>
            <a
              href="https://www.ticketmaster.pl/artist/justin-bieber-bilety/70683"
              class="btn text-light bg-success px-4"
              >Buy a ticket</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselVertical",
};
</script>

<style scoped>
.carousel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}
img {
  transition: 0.3s linear;
  width: 102%;
}
.carousel-item:hover img {
  filter: sepia(0.4);
  transform: scale(1.04);
}
.carousel-inner {
  display: none;
}
.carousel-item {
  height: 255px;
  overflow: hidden;
}
.carousel-inner .carousel-item:nth-child(3) img{
  transform: translateY(-21px);
}
.carousel-inner .carousel-item:nth-child(3):hover img{
  filter: sepia(0.4);
  transform: translateY(-26px) scale(1.04);
}
h2 {
  margin: 0;
  padding: 5px 0 4px 0;
  font-weight: 600;
}
.item-overlay {
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  left: -100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.3s ease-in;
  transform: scale(1);
  background: rgb(0, 0, 0);
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.700717787114846) 30%,
    rgba(0, 212, 255, 0) 100%
  );
  filter: blur(3);
}
.carousel-item:hover .item-overlay {
  opacity: 1;
  transform: scale(1);
  left: 50%;
  transform: translateX(-50%);
}
.text {
  background-color: black;
  border-radius: 5px;
  padding: 5px;
}
.item-info:hover a {
  box-shadow: inset 0px 0px 79px -45px rgba(0, 0, 0, 1);
}

@media (min-width: 1030px) {
  h2 {
    margin-right: 10px;
    font-size: 28px;
    width: 300px;
    height: auto;
    padding-top: 30px;
    text-align: center;
    margin-bottom: 0;
  }
  .carousel-inner:nth-child(2) {
    margin-right: 10px;
  }
  .carousel {
    flex-direction: row;
    width: 1000px;
    height: 240px;
    margin: 1rem auto 0 auto;
  }
  .carousel-inner {
    display: block;
    border-radius: 5px;
  }
  .item-overlay {
    left: -100%;
  }
}
@media (min-width: 1480px) {
  h2 {
    font-size: 28px;
    height: auto;
    width: 100%;
    padding-top: 0;
    text-align: center;
  }
  .carousel {
    flex-direction: column;
    width: 450px;
    height: auto;
  }
  .carousel-inner {
    padding-top: 0;
    display: block;
  }
  .carousel-top {
    margin-bottom: 1rem;
  }
}
@media (min-width: 2000px) {
  .carousel-item {
    height: 390px;
    overflow: hidden;
  }
  .carousel {
    flex-direction: column;
    width: 680px;
    height: auto;
  }
}
</style>
